<template>
    <div class="page-coach-feedback-record">
        <HeadBar left-arrow @click-left="newAppBack" title="反馈记录" />
        <van-list v-model="loading" :finished="finished" @load="loadList" :immediate-check="false" :offset="50"
            finished-text="没有更多了" v-if="hasData">
            <div class="list">
                <div class="model" v-for="item in list" @click="toDetail(item)">
                    <div class="line-coach">
                        <div>{{ roleJson[item.roleType] }}</div>
                        <van-icon name="arrow" />
                    </div>
                    <div class="line-qus ellipsis">{{ item.content }}</div>
                    <div class="line-time">{{ item.submitTime }}</div>
                </div>
            </div>
        </van-list>
        <div class="nodata" v-else>
            <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/coach-feedback/null.png" class="nimg" />
            <div class="ntext">暂无数据</div>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import HeadBar from '@/components/app/headBar';

export default {
    mixins: [userMixin],
    components: {
        HeadBar
    },
    data() {
        return {
            list: [],
            pageNum: 1,
            finished: false,
            loading: false,
            hasData: true,
            roleJson: {
                1: '私教教练', 2: '团课教练', 3: '兼职教练', value: '3', 4: '管理角色'
            }
        }
    },
    async created() {
        await this.$getAllInfo(['teacherId', 'teacherToken']); console.log(this.teacherId)
        initBack();
        this.getData();
    },
    methods: {
        newAppBack,
        toDetail(item) {
            this.$router.push('/coach-feedback/detail?id=' + item.teacherFeedbackId)
        },
        loadList() {
            console.log('load')
            this.pageNum += 1;
            this.getData();
        },
        getData(refresh) {
            const size = 10;
            this.$axios.post(this.baseURLApp + "/teacher/feedback/page", {
                hasWx: true,
                teacherId: this.teacherId,
                pageNum: this.pageNum,
                pageSize: size
            }).then((res) => {
                const d = res.data.records || [];
                if (!d.length && this.list.length == 0) {
                    this.hasData = false;
                } else {
                    this.hasData = true;
                }
                if (refresh) {
                    this.list = d;
                } else {
                    this.list.push(...d);
                }
                this.finished = d.length < size;
                this.loading = false;
            })
        },
    },
}
</script>
<style lang="less" scoped>
.page-coach-feedback-record {
    background: #F5F5F5;
    min-height: 100vh;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .list {
        padding: 32px;

        .model {
            height: 196px;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 32px;
            margin-bottom: 28px;
            color: #000;
            &:last-of-type{
                margin-bottom: 0;
            }

            .line-coach {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                font-size: 28px;
                line-height: 1;
            }

            .line-qus {
                font-size: 24px;
                margin: 32px 0 24px;
            }

            .line-time {
                font-size: 24px;
                line-height: 1;
            }
        }

    }

    .nodata {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .nimg {
            width: 240px;
            height: 240px;
        }

        .ntext {
            margin-top: 48px;
            font-size: 28px;
            color: #000;
            text-align: center;
            line-height: 1;
        }
    }
}
</style>